
import Owner from "./views/Owner/index.js";
import Supplier from "./views/Supplier/index.js";
import FeeController from "./views/FeeController/index.js";
import Settings from "./views/Settings/index.js";
import TokenDetails from "./views/TokenDetails/index.js";
import SilverProtector from "./views/SilverProtector/index";

var routes = [
  {
    layout: "/home",
    path: "/",
    component: TokenDetails,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/tokenDetails",
    name: "Token Details",
    component: TokenDetails,
    icon: "tim-icons icon-money-coins",
  },
  {
    layout: "/home",
    path: "/silverProtector",
    name: "Silver Protector",
    component: SilverProtector,
    icon: "tim-icons icon-lock-circle",
  },
  {
    layout: "/home",
    path: "/supplier",
    name: "Supply Controller",
    component: Supplier,
    icon: "tim-icons icon-coins",
  },
  {
    layout: "/home",
    path: "/feeController",
    name: "Fee Controller",
    component: FeeController,
    icon: "tim-icons icon-molecule-40",
  },
  {
    layout: "/home",
    path: "/owner",
    name: "Change Owner",
    component: Owner,
    icon: "tim-icons icon-single-02",
  },
  {
    layout: "/home",
    path: "/settings",
    name: "Admin Settings",
    component: Settings,
    icon: "tim-icons icon-settings-gear-63",
  },
];

export default routes;