import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { web3 } from '../web3';
import { ServerURL } from '../config';
import { saveloginData, setSupplierHistory, setOwnerHistory, setFreezeHistory } from '../actions/Auth';

/*========== NONCE FUNCTIONS =============*/

function* getNonce({ payload, history }) {
  const { error, response } = yield call(getCall, `/users/getNonce/${payload}`);
  if (error) {
    yield put({ type: "IS_LOGIN_DISABLED" });
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    var nonceObj = response['data']['body']['nonceObject'];
    // SIGING THE NONCE
    try {
      const signature = yield web3.eth.personal.sign(web3.utils.utf8ToHex(`Login to TEX Token - Nonce: ,${nonceObj["nonce"]}`), payload)
      const data = {
        signature,
        publicAddress: payload,
      }
      const metaMaskResponse = yield call(postCall, { path: `/users/loginWithMetaMask`, payload: data });

      if (error) {
        yield put({ type: "IS_LOGIN_DISABLED" });
        EventBus.publish("error", error['response']['data']['message']);
      }
      else if (metaMaskResponse) {
        let { token } = metaMaskResponse['response']['data']['body']
        const decoded = jwt_decode(token, { header: false });
        let role = decoded["role"];
        if (role !== "admin" && role !== "supplyController" && role !== "feeController" && role !== "assetProtector") {
          EventBus.publish("error", "Can't login through other account");
          yield put({ type: "IS_LOGIN_DISABLED" });
          return;
        }
        let data = { token, role }
        yield put(saveloginData(data));
        yield put({ type: "IS_LOGIN_DISABLED" });

        EventBus.publish("success", metaMaskResponse['response']['data']['message'])
        setTimeout(() => history.push('/home'), 1000);
      }
    } catch (e) { yield put({ type: "IS_LOGIN_DISABLED" }); }
  }
};


/*========== OWNER FUNCTIONS =============*/

function* getOwnerHistory() {
  const { error, response } = yield call(getCall, '/blockchain/historyOfOwnerShip');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setOwnerHistory(response['data']['body']));
};

function* setProposedOwner({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/proposeOwner`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'TOGGLE_PURPOSE_OWNER' });
};

function* claimOwnership({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/claimOwnership`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'IS_CLAIM_DISABLED' });
};


/*========== SUPPLIER FUNCTIONS =============*/

function* getSupplierHistory() {
  const { error, response } = yield call(getCall, '/blockchain/supplyHistory');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setSupplierHistory(response['data']['body']));
};


function* addSupplier({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/setNewSupplyController`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'TOGGLE_SUPPLIER_MODAL' });
};


/*========== MINT TOKENS =============*/

function* mintTokens({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/mint`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'TOGGLE_MINTING_MODAL', payload: false });
  yield put({ type: 'GET_SUPPLIER_HISTORY' });
};

/*========== BURN TOKENS =============*/

function* burnTokens({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/burn`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'TOGGLE_BURN_MODAL' });
  yield put({ type: 'GET_SUPPLIER_HISTORY' });
};

/*========== PROTECTOR API's =============*/

function* setProtector({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/setNewAssetProtector`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

function* freezeAddress({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/freezeAddress`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'GET_FREEZE_HISTORY' });
};

function* getFreezeHistory() {
  const { error, response } = yield call(getCall, '/blockchain/getFreezedAddresses');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setFreezeHistory(response['data']['body']));
};

function* unFreeze({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/unFreezeAddress`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'GET_FREEZE_HISTORY' });
}

/*========== FEE CONTROLLER TOKENS =============*/

function* setFeeController({ payload }) {
  const { error, response } = yield call(postCall, { path: `/blockchain/setNewFeeController`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put({ type: 'TOGGLE_FEE_CONTROLLER' });
};

function* actionWatcher() {
  yield takeEvery('UN_FREEZE', unFreeze);
  yield takeEvery('GET_NONCE', getNonce);
  yield takeEvery('GET_OWNER_HISTORY', getOwnerHistory);
  yield takeEvery('CLAIM_OWNERSHIP', claimOwnership);
  yield takeEvery('SET_PROPOSED_OWNER', setProposedOwner);
  yield takeEvery('GET_SUPPLIER_HISTORY', getSupplierHistory);
  yield takeEvery('ADD_SUPPLIER', addSupplier);
  yield takeEvery('BURN_TOKENS', burnTokens);
  yield takeEvery('MINT_TOKENS', mintTokens);
  yield takeEvery('SET_PROTECTOR', setProtector);
  yield takeEvery('FREEZE_ADDRESS', freezeAddress);
  yield takeEvery('GET_FREEZE_HISTORY', getFreezeHistory);
  yield takeEvery('SET_FEE_CONTROLLER', setFeeController);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getServerCall(path) {
  return new Promise(async (resolve, reject) => {
    fetch(`${ServerURL}/buySilver/texPending/${path}`)
      .then(response => response.json())
      .then(response => {
        // console.log('*******response:::', response);
        return resolve(response);
      }).catch(error => {
        // console.log('*******error:::', error);
        return reject(error);
      });
  })
}



function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
