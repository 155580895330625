
/*========== LOGIN ACTIONS ============= */

export const saveloginData = (data) => ({
  type: 'SAVE_LOGIN_DATA',
  payload: data,
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const isClaimDisabled = () => ({
  type: 'IS_CLAIM_DISABLED',
});

export const claimOwnership = (data) => ({
  type: 'CLAIM_OWNERSHIP',
  payload: data,
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

export const setBalance = (data) => ({
  type: 'SET_BALANCE',
  payload: data
});

/*========== NONCE ACTIONS ============= */

export const getNonce = ({ address, history }) => ({
  type: 'GET_NONCE',
  payload: address,
  history
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});

export const signingNonce = (data) => ({
  type: 'SIGNING_NONCE',
  payload: data,
});

/*========== OWNER ACTIONS ============= */

export const getOwnerHistory = (data) => ({
  type: 'GET_OWNER_HISTORY',
  payload: data
});

export const setOwnerHistory = (data) => ({
  type: 'SET_OWNER_HISTORY',
  payload: data
});

export const togglePurposeOwner = () => ({
  type: 'TOGGLE_PURPOSE_OWNER',
});

export const setProposedOwner = (data) => ({
  type: 'SET_PROPOSED_OWNER',
  payload: data
});

export const togglePauseContract = () => ({
  type: 'TOGGLE_PAUSE_CONTRACT',
});

/*========== SUPPLIER ACTIONS ============= */

export const getSupplierHistory = () => ({
  type: 'GET_SUPPLIER_HISTORY',
});

export const setSupplierHistory = (data) => ({
  type: 'SET_SUPPLIER_HISTORY',
  payload: data
});


export const toggleSupplierModal = () => ({
  type: 'TOGGLE_SUPPLIER_MODAL',
});

export const addSupplier = (data) => ({
  type: 'ADD_SUPPLIER',
  payload: data
});

/*========== MINTING ACTIONS ============= */

export const mintTokens = (data) => ({
  type: 'MINT_TOKENS',
  payload: data
});

export const toggleMintingModal = (data) => ({
  type: 'TOGGLE_MINTING_MODAL',
  payload: data
});

export const toggleHistorySlider = () => ({
  type: 'TOGGLE_HISTORY_SLIDER',
});

/*========== Burn ACTIONS ============= */

export const burnTokens = (data) => ({
  type: 'BURN_TOKENS',
  payload: data
});

export const toggleBurnModal = () => ({
  type: 'TOGGLE_BURN_MODAL',
});


/*========== FEE CONTROLLER ACTIONS ============= */

export const toggleSetFee = () => ({
  type: 'TOGGLE_SET_FEE',
});

export const toggleFeeController = () => ({
  type: 'TOGGLE_FEE_CONTROLLER',
});

/*========== Silver Protector ACTIONS ============= */

export const setProtector = (data) => ({
  type: 'SET_PROTECTOR',
  payload: data
});

export const freezeAddress = (data) => ({
  type: 'FREEZE_ADDRESS',
  payload: data
});

export const getFreezeHistory = () => ({
  type: 'GET_FREEZE_HISTORY'
});

export const setFreezeHistory = (data) => ({
  type: 'SET_FREEZE_HISTORY',
  payload: data
});

export const unFreeze = (data) => ({
  type: 'UN_FREEZE',
  payload: data
});
export const setFeeController = (data) => ({
  type: 'SET_FEE_CONTROLLER',
  payload: data
});

export const toggleFeeRecipient = () => ({
  type: 'TOGGLE_FEE_RECIPIENT',
});
