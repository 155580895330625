import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import Validator from 'wallet-address-validator';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import AppLoader from "../../components/AppLoader";
import { Token, TokenAddress } from '../../store/contract/index';
import { togglePurposeOwner, setProposedOwner, togglePauseContract, getOwnerHistory, } from '../../store/actions/Auth.js';

class Owner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      owner: '',
      isOwnership: false,
      paused: true,
      isPaused: true,
      ownerAddress: '',
    };
    props.getOwnerHistory();
  };

  async componentDidMount() {
    this.checkContractState();

  };

  async componentWillReceiveProps({ isPurposeModal }) {
    this.checkContractState();
    if (!isPurposeModal) this.setState({ address: '' });
  };

  checkContractState = async () => {
    let ownerAddress = await Token.methods.owner().call();
    let paused = await Token.methods.paused().call();
    this.setState({ paused, ownerAddress })
  };

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  submitProposedOwner = async () => {
    let { address, ownerAddress } = this.state;
    if (address == '') {
      EventBus.publish('error', 'Please Enter the Address');
      return;
    };

    if (address == ownerAddress) {
      EventBus.publish('info', 'Address have already Ownership');
      return;
    };

    let validAddress = Validator.validate(address, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    this.setState({ isOwnership: true });
    let from = (await web3.currentProvider.enable())[0];

    /** Add Proposed Owner **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.proposeOwner(address).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.setProposedOwner(data);
        this.setState({ isOwnership: false });
      }).on('error', e => {
        this.setState({ isOwnership: false });
        EventBus.publish('error', `Unable to Add Ownership, Please View details on Explorer`);
      });
  };

  handlePauseContract = async () => {
    let { paused } = this.state;
    let from = (await web3.currentProvider.enable())[0];
    this.setState({ isPaused: false });
    if (paused) {
      /** unpause Contract **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.unpause().encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          let data = { transactionHash: receipt['transactionHash'] };
          EventBus.publish('success', `Contract is ${paused ? 'Unpause' : 'Pause'} successfully`);
          this.setState({ isPaused: true });
          this.props.togglePauseContract();
        }).on('error', e => {
          EventBus.publish('error', `Unable to ${paused ? 'Unpause' : 'Pause'}, Please View details on Explorer`);
          this.props.togglePauseContract();
          this.setState({ isPaused: true });
        });
    }
    else {
      /** Pause Contract **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.pause().encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          let data = { transactionHash: receipt['transactionHash'] };
          EventBus.publish('success', `Contract is ${paused ? 'Unpause' : 'Pause'} successfully`);
          this.setState({ isPaused: true });
          this.props.togglePauseContract();
        }).on('error', e => {
          EventBus.publish('error', `Unable to ${paused ? 'Unpause' : 'Pause'}, Please View details on Explorer`);
          this.props.togglePauseContract();
          this.setState({ isPaused: true });
        });
    }
  }

  render() {
    let { address, ownerAddress, isOwnership, paused, isPaused } = this.state;
    let { isPurposeModal, isPauseModal, ownerHistory } = this.props;
    const columns = [
      {
        id: 'date',
        Header: 'Date',
        accessor: ownerHistory => ownerHistory['createdAt'] ? moment(ownerHistory["createdAt"]).format("lll") : '-',
      }, {
        id: 'proposedAddress',
        Header: 'Proposed Address',
        accessor: ownerHistory => ownerHistory['publicAddress']
          ? <a href={`${explorer}/address/${ownerHistory['publicAddress']}`} target='_blank' style={{ color: '#4c4c4c' }}>
            View on Explorer
          </a>
          : '-',
      },
      //  {
      //   id: 'transactionDetails',
      //   Header: 'Transaction Details',
      //   accessor: ownerHistory => ownerHistory['transactionHash']
      //     ? <a href={`${explorer}/tx/${ownerHistory['transactionHash']}`} target='_blank' style={{ color: '#4c4c4c' }}>
      //       View on Explorer
      //     </a>
      //     : '-',
      // }
    ];

    return (
      <div className='content'>
        <div className="main-container owner">
          <Fragment>
            <div className='main-container-head mb-3'>
              <p className="main-container-heading">Ownership Address: 
              <span className="owner-address ml-2">{ownerAddress}</span></p>
              <div>
                <Button variant="outlined" className="add-btn mr-3" onClick={this.props.togglePauseContract}>{paused ? 'Unpause' : 'Pause'} Contract</Button>
                <Button variant="outlined" className="add-btn" onClick={this.props.togglePurposeOwner}>Propose Owner</Button>
              </div>
            </div>
            <ReactTable
              className="table"
              data={ownerHistory}
              resolveData={ownerHistory => ownerHistory.map(row => row)}
              columns={columns}
              minRows={20}
              filterable={true}
            />
          </Fragment>
          {/* } */}
        </div>

        {/* ---------------PURPOSED OWNER MODAL--------------- */}

        <Modal isOpen={isPurposeModal} toggle={this.props.togglePurposeOwner} className="main-modal purpose-modal">
          <ModalHeader toggle={this.props.togglePurposeOwner}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title text-center"><p className='text-white'>Add Ownership</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.submitProposedOwner}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='address'
                    value={address}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Address of Owner <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isOwnership
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------PAUSE MODAL--------------- */}
        <Fragment>
          <Modal className="pause-modal" isOpen={isPauseModal} toggle={this.props.togglePauseContract}>
            <ModalHeader toggle={this.props.togglePauseContract}>
              <p className="modal-title text-dark">{paused ? 'Unpause' : 'Pause'} Contract</p>
            </ModalHeader>
            <ModalBody>
              {isPaused
                ? <p className="text-dark">Are you sure you want to {paused ? 'Unpause' : 'Pause'} the Contract?</p>
                : <div className="my-4">
                  <AppLoader message="Please Wait...." />
                </div>
              }
            </ModalBody>
            <ModalFooter>
              {isPaused &&
                <Fragment>
                  <Button className="cancel-btn col-3" onClick={this.props.togglePauseContract}>Cancel</Button>
                  <Button className="confirm-btn col-3" onClick={this.handlePauseContract}>Confirm</Button>
                </Fragment>
              }
            </ModalFooter>
          </Modal>
        </Fragment>

      </div>
    );
  }
}

const mapDispatchToProps = {
  togglePurposeOwner, setProposedOwner, togglePauseContract, getOwnerHistory,
};

const mapStateToProps = ({ Auth }) => {
  let { isPurposeModal, isPauseModal, ownerHistory } = Auth;
  return { isPurposeModal, isPauseModal, ownerHistory };
};
export default connect(mapStateToProps, mapDispatchToProps)(Owner);