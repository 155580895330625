import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import Validator from 'wallet-address-validator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import AppLoader from "../../components/AppLoader";
import { Token, TokenAddress } from '../../store/contract/index';
import { toggleSetFee, toggleFeeController, setFeeController, toggleFeeRecipient } from '../../store/actions/Auth.js';
import { Fragment } from 'react';

class FeeController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeRate: '',
      rate: '',
      feePercent: '',
      feePercentSet: '',
      isFeeRate: false,
      feeController: '',
      address: '',
      isFeeController: false,
      feeRecipient: '',
      recipientAddress: '',
      isFeeRecipient: false,
    }
  };

  async componentDidMount() {
    this.updateFeeController();
  };

  componentWillReceiveProps({ setFeeModal, setFeeController, setFeeRecipient }) {
    if (!setFeeModal) this.setState({ feePercent: '' });
    if (!setFeeController) this.setState({ address: '' });
    if (!setFeeRecipient) this.setState({ recipientAddress: '' });
    this.updateFeeController();
  };

  updateFeeController = async () => {
    let ownerAddress = await Token.methods.owner().call();
    let userAddress = (await web3.currentProvider.enable())[0];

    let feeRate = await Token.methods.feeRate().call();
    let feeParts = await Token.methods.feeParts().call();
    let feePercentSet = feeRate / feeParts * 100;
    let feeController = await Token.methods.feeController().call();
    let feeRecipient = await Token.methods.feeRecipient().call();
    this.setState({ feePercentSet, feeRate, feeController, feeRecipient, userAddress, ownerAddress })
  }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  setFeeRate = async () => {
    let { feePercent } = this.state;
    if (feePercent == '') {
      EventBus.publish('error', 'Please Enter the Rate');
      return;
    }
    if (feePercent > 100) {
      EventBus.publish('error', 'Fee Percent should not be greater than 10 percent');
      return;
    }
    if (feePercent < 0) {
      EventBus.publish('error', 'Fee Percent should not be let than 0 percent');
      return;
    }

    let feeParts = await Token.methods.feeParts().call();
    let rate = feePercent * feeParts / 100; // Fee Rate

    console.log('******Rate  = ', rate);
    let from = (await web3.currentProvider.enable())[0];
    this.setState({ isFeeRate: true });
    /** SET FEE RATE **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.setFeeRate(rate).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        EventBus.publish('success', `Set Fee Rate Successfully`);
        this.props.toggleSetFee();
        this.setState({ isFeeRate: false });
      }).on('error', e => {
        this.setState({ isFeeRate: false });
        EventBus.publish('error', `Unable to Set Fee Rate, Please View details on Explorer`);
      });
  };

  setFeeController = async () => {
    let { address } = this.state;
    if (address == '') {
      EventBus.publish('error', 'Please Enter the Address');
      return;
    }

    let validAddress = Validator.validate(address, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    let from = (await web3.currentProvider.enable())[0];
    this.setState({ isFeeController: true });
    /** SET FEE CONTROLLER **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.setFeeController(address).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.setFeeController(data)
        this.setState({ isFeeController: false });
      }).on('error', e => {
        this.setState({ isFeeController: false });
        EventBus.publish('error', `Unable to Set Fee Controller, Please View details on Explorer`);
      });
  };

  setFeeRecipient = async () => {
    let { recipientAddress, feeRecipient } = this.state;
    if (recipientAddress == '') {
      EventBus.publish('error', 'Please Enter the Address');
      return;
    };

    if (recipientAddress == feeRecipient) {
      EventBus.publish('info', 'Fee Recipient has been already added');
      return;
    };

    let validAddress = Validator.validate(recipientAddress, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    let from = (await web3.currentProvider.enable())[0];
    this.setState({ isFeeRecipient: true });
    /** SET FEE RECIPIENT **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.setFeeRecipient(recipientAddress).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        EventBus.publish('success', `Set Fee Recipient Successfully`);
        this.props.toggleFeeRecipient();
        this.setState({ isFeeRecipient: false });
      }).on('error', e => {
        this.setState({ isFeeRecipient: false });
        EventBus.publish('error', `Unable to Set Fee Controller, Please View details on Explorer`);
      });
  };

  render() {
    let { setFeeModal, setControllerModal, setRecipientModal } = this.props;
    let { ownerAddress, userAddress, feePercent, feePercentSet, isFeeRate, feeController, address, isFeeController, feeRecipient, recipientAddress, isFeeRecipient } = this.state;
    console.log("***********feePercentSet::", feePercentSet);
    console.log("***********feeController::", feeController);
    console.log("***********feeRecipient::", feeRecipient);
    return (
      <div className='content'>
        <div className="main-container pb-5">
          <div className='main-container-head mb-3 mt-3'>
            <p className="main-container-heading">Fee Controller</p>
          </div>
          <div className="edit-add col-12">
            <div className="edit-add-body mt-2 col-12">
              {feeController == '' || feeRecipient == ''
                ? <AppLoader message='Loading...' />
                : <Fragment>
                  <div className='row fee-controller'>
                    <p className="text-dark py-2"><span className="view-data-title">Fee Percent:</span> {feePercentSet}%</p>
                    {userAddress && userAddress.toLowerCase() == feeController.toLowerCase() &&
                      <Button variant="outlined" className="view-btn ml-3 my-1" onClick={this.props.toggleSetFee}>Set Fee Percent</Button>
                    }
                  </div>
                  <div className='row fee-controller'>
                    <p className="text-dark py-2"><span className="view-data-title">Fee Controller:</span> {feeController} </p>
                    {userAddress && (userAddress.toLowerCase() == feeController.toLowerCase() || userAddress.toLowerCase() == ownerAddress.toLowerCase()) &&
                      <Button variant="outlined" className="view-btn ml-3 my-1" onClick={this.props.toggleFeeController}>Set Controller</Button>
                    }
                  </div>
                  <div className='row fee-controller'>
                    <p className="text-dark py-2"><span className="view-data-title">Fee Recipient:</span> {feeRecipient} </p>
                    {userAddress && userAddress.toLowerCase() == feeController.toLowerCase() &&
                      <Button variant="outlined" className="view-btn ml-3 my-1" onClick={this.props.toggleFeeRecipient}>Set Recipient</Button>
                    }
                  </div>
                </Fragment>
              }
            </div>
          </div>
        </div>

        {/* ---------------SET FEE RATE MODAL--------------- */}
        <Modal isOpen={setFeeModal} toggle={this.props.toggleSetFee} className="main-modal fee-rate-modal">
          <ModalHeader toggle={this.props.toggleSetFee}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="fee-rate-modal-title"><p>SET FEE PERCENT</p></div>
            <div className="fee-rate-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setFeeRate}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='feePercent'
                    value={feePercent}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Percent <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isFeeRate
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------SET FEE CONTROLLER MODAL--------------- */}
        <Modal isOpen={setControllerModal} toggle={this.props.toggleFeeController} className="main-modal fee-rate-modal">
          <ModalHeader toggle={this.props.toggleFeeController}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="fee-rate-modal-title"><p>SET FEE CONTROLLER</p></div>
            <div className="fee-rate-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setFeeController}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='address'
                    value={address}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>New Controller Address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isFeeController
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------SET FEE RECIPIENT MODAL--------------- */}
        <Modal isOpen={setRecipientModal} toggle={this.props.toggleFeeRecipient} className="main-modal fee-rate-modal">
          <ModalHeader toggle={this.props.toggleFeeRecipient}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="fee-rate-modal-title"><p>SET FEE RECIPIENT</p></div>
            <div className="fee-rate-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setFeeRecipient}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='recipientAddress'
                    value={recipientAddress}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>New Recipient Address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isFeeRecipient
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleSetFee, toggleFeeController, setFeeController, toggleFeeRecipient
};

const mapStateToProps = ({ Auth }) => {
  let { role, setFeeModal, setControllerModal, setRecipientModal } = Auth;
  return { role, setFeeModal, setControllerModal, setRecipientModal }
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeController);