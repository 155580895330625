import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { web3 } from "../../store/web3";
import logo from '../../assets/img/icon.png';
import AppLoader from "../../components/AppLoader";
import { Token, TokenAddress } from '../../store/contract/index';
import { isloginDisabled, getNonce, isClaimDisabled, claimOwnership } from "../../store/actions/Auth";

import './index.css';
import { Fragment } from 'react';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
      owner: '',
      proposedOwner: '',
      supplyController: '',
      feeRecipient: '',
    };
  };

  async componentDidMount() {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };
    this.checkAddresses();
  };

  componentWillReceiveProps() {
    this.checkAddresses();
  }

  checkAddresses = async () => {
    let address = (await web3.currentProvider.enable())[0];
    // CHECK OWNER
    let ownerAddress = await Token.methods.owner().call();
    let owner = ownerAddress.toLowerCase();

    // CHECK PROPOSED OWNER
    let proposedOwnerAddress = await Token.methods.proposedOwner().call();
    let proposedOwner = proposedOwnerAddress.toLowerCase();

    // CHECK SUPPLIER OWNER
    let supplyControllerAddress = await Token.methods.supplyController().call();
    let supplyController = supplyControllerAddress.toLowerCase();

    // Silver Protector
    let protectorController = (await Token.methods.assetProtectionRole().call()).toLowerCase();

    // CHECK FEE CONTROLLER 
    let feeControllerAddress = await Token.methods.feeController().call();
    let feeController = feeControllerAddress.toLowerCase();

    // CHECK FEE RECIPIENT 
    let feeRecipientAddress = await Token.methods.feeRecipient().call();
    let feeRecipient = feeRecipientAddress.toLowerCase();

    this.setState({ address, owner, proposedOwner, supplyController, feeController, protectorController, feeRecipient });
  };


  handleLogin = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
    this.props.isloginDisabled();
    this.props.getNonce({ address, history: this.props.history });
  };

  handleClaimOwnership = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };
    this.props.isClaimDisabled();
    let from = (await web3.currentProvider.enable())[0];

    /** Claim Proposed Owner **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.claimOwnership().encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.claimOwnership(data);
      }).on('error', e => {
        this.props.isClaimDisabled();
        EventBus.publish('error', `Unable to Claim Ownership`);
      });
  };

  render() {
    let { isLogin, isClaim } = this.props;
    let { address, owner, proposedOwner, supplyController, feeController, protectorController, feeRecipient } = this.state;

    return (
      <div className="login-page">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <div className="login-form">
              <p className="login-title">【ＬＯＧＩＮ】</p>
              <hr className='mt-3' />
              {address == ''
                ? <AppLoader />
                : <Fragment>
                  {address == proposedOwner
                    ? <ValidatorForm className="validator-form mt-4" onSubmit={this.handleClaimOwnership}>
                      <Button type="Submit" variant="contained" className='text-white login-btn mt-4'
                        disabled={isClaim}>
                        {!isClaim
                          ? "CLAIM OWNERSHIP"
                          : <div><i className="fa fa-spinner fa-spin"></i></div>
                        }
                      </Button>
                    </ValidatorForm>
                    : (address == owner || address == supplyController || address == feeController || address == protectorController)
                      ? <ValidatorForm className="validator-form mt-4" onSubmit={this.handleLogin}>
                        <Button type="Submit" variant="contained" className='text-white login-btn mt-4'
                          disabled={isLogin}>
                          {!isLogin
                            ? "LOGIN WITH METAMASK"
                            : <div><i className="fa fa-spinner fa-spin"></i></div>
                          }
                        </Button>
                      </ValidatorForm>
                      : address == feeRecipient
                        ? <div className="login-text pt-4"><p>Fee Recipient can't login!</p></div>
                        : <div className="login-text pt-4"><p>MetaMask Address is not Valid!</p></div>
                  }
                </Fragment>
              }
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <img className="login-page-logo" src={logo} alt='logo' />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  isloginDisabled,
  getNonce,
  isClaimDisabled,
  claimOwnership,
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, isClaim } = Auth
  return { isLogin, isClaim }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);