import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Drawer from '@material-ui/core/Drawer';
import React, { Component, Fragment } from 'react';

import './index.css';
import { explorer } from '../../store/config';
import { getSupplierHistory, toggleHistorySlider } from '../../store/actions/Auth.js';

export class Slider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            right: false,
        };
        props.getSupplierHistory();
    };


    render() {
        let { supplierHistory, isHistorySlider } = this.props;
        const columns = [
            {
                id: 'date',
                Header: 'Date',
                accessor: supplierHistory => supplierHistory['transactionTime'] ? moment(supplierHistory["transactionTime"]).format("lll") : '-',
            }, {
                id: 'publicAddress',
                Header: 'Public Address',
                accessor: supplierHistory => supplierHistory['publicAddress']
                    ? <a href={`${explorer}/address/${supplierHistory['publicAddress']}`} target='_blank' style={{ color: '#4c4c4c' }}>
                        View on Explorer
          </a>
                    : '-',
            }, {
                id: 'amountOfTokens',
                Header: 'Amount of Tokens',
                accessor: supplierHistory => supplierHistory['amountOfTokens'] ? supplierHistory['amountOfTokens'] : '-',
            }, {
                id: 'transactionDetails',
                Header: 'Transaction Details',
                accessor: supplierHistory => supplierHistory['transactionHash']
                    ? <a href={`${explorer}/tx/${supplierHistory['transactionHash']}`} target='_blank' style={{ color: '#4c4c4c' }}>
                        View on Explorer
          </a>
                    : '-',
            }, {
                id: 'type',
                Header: 'Type',
                accessor: supplierHistory => supplierHistory['type'] ? (supplierHistory['type']).toUpperCase() : '-',
            },
        ];

        return (
            <div>
                <Drawer anchor='right' open={isHistorySlider} onClose={this.props.toggleHistorySlider}>
                    <div className="slider" role="presentation">
                        <div className="slider-heading mt-4 mb-5"><p>History</p></div>
                        <ReactTable
                            className="table"
                            data={supplierHistory}
                            resolveData={supplierHistory => supplierHistory.map(row => row)}
                            columns={columns}
                            minRows={20}
                            filterable={true}
                        />
                    </div>
                </Drawer>
            </div>
        )
    }
}

const mapDispatchToProps = { getSupplierHistory, toggleHistorySlider };
const mapStateToProps = ({ Auth }) => {
    let { isHistorySlider, supplierHistory } = Auth;
    return { isHistorySlider, supplierHistory };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);